import { h, VNode, Fragment } from "preact";
import { NalaLogo } from "../../nalaLogo/nalaLogo";
import s from "./compareRatesTab.module.css";
import { RateContainer } from "../../rateContainer";
import { useEffect, useMemo, useReducer } from "preact/hooks";
import { reducer } from "../../../reducer/reducer";
import { DropdownButton } from "../../dropdown/button";
import uniqby from "lodash.uniqby";
import { RateLabel } from "../../label";
import Big from "big.js";
import { CTA } from "../../cta";
import { formatDateToLocalTimeWithZone } from "../utils";
import { Typography, TypographyFooter } from "../../typography";
import { TabProps } from "./types";

export const CompareRatesTab = ({
  rates: allRates,
  downloadNalaLink,
  fromCurrency,
  toCurrency,
  onFromCurrencyChange,
  onToCurrencyChange,
}: TabProps): VNode => {
  const [state, dispatch] = useReducer(reducer, {
    fromCurrency,
    toCurrency,
    fromAmount: "1000",
    toAmount: "0",
  });

  useEffect(() => {
    if (fromCurrency) {
      dispatch({ type: "fromCurrencyChange", payload: fromCurrency });
    }
  }, [fromCurrency]);

  useEffect(() => {
    if (toCurrency) {
      dispatch({ type: "toCurrencyChange", payload: toCurrency });
    }
  }, [toCurrency]);

  useEffect(() => {
    if (allRates && allRates.length > 0) {
      dispatch({ type: "addPairs", payload: allRates });
      dispatch({ type: "fromAmountChange", payload: "1000" });
    }
  }, [allRates]);

  const sourceCurrencies = useMemo(
    () =>
      allRates &&
      uniqby(
        allRates
          .map((rate) => rate.sourceCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [allRates]
  );

  const destinationCurrencies = useMemo(
    () =>
      allRates &&
      uniqby(
        allRates
          .map((rate) => rate.destinationCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [allRates]
  );

  const compareRates = useMemo(() => {
    if (!allRates || !fromCurrency || !toCurrency) return [];

    return allRates
      .filter(
        (rate) =>
          rate.sourceCurrency.currencyCode === fromCurrency.currencyCode &&
          rate.destinationCurrency.currencyCode === toCurrency.currencyCode
      )
      .reduce<CalculatorRate[]>((acc, rate) => {
        const duplicateIndex = acc.findIndex(
          (existingRate) =>
            existingRate.rate === rate.rate &&
            existingRate.providerName === rate.providerName
        );

        if (duplicateIndex === -1) {
          acc.push(rate);
        }
        return acc;
      }, [])
      .sort((a, b) => parseFloat(b.rate) - parseFloat(a.rate));
  }, [allRates, fromCurrency, toCurrency]);

  const fxRateHumanFriendly = (rate: CalculatorRate) => {
    const multipliedRate = Big(rate.rate).times(1000).round(2, Big.roundUp);

    const formattedRate = Number(multipliedRate).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formattedRate} ${rate.destinationCurrency.currencyCode}`;
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className={s.tabContainer}>
      <div className={s.inputRow}>
        <div className={s.inputColumn}>
          <RateLabel>When sending</RateLabel>
          <DropdownButton
            initialValue={fromCurrency}
            currencies={sourceCurrencies}
            onChange={onFromCurrencyChange}
          />
        </div>
        <div className={s.arrowContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="16"
            viewBox="0 0 26 16"
            fill="none"
          >
            <path
              d="M1.97322 8L24.0268 8M24.0268 8L18.0893 2.0625M24.0268 8L18.0893 13.9375"
              stroke="var(--color-blue)"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className={s.inputColumn}>
          <RateLabel>Choose currency</RateLabel>
          <DropdownButton
            initialValue={toCurrency}
            currencies={destinationCurrencies}
            onChange={onToCurrencyChange}
            rightAligned
          />
        </div>
      </div>
      {compareRates.length > 0 ? (
        <div>
          <h3 className={s.rateHeading}>Today's rates {formattedDate}</h3>
          <div className={s.ratesTable}>
            <Typography className={s.ratesTableHeadings}>
              Sending with
            </Typography>
            <Typography
              className={`${s.recipientHeading} ${s.ratesTableHeadings}`}
            >
              Recipient gets
            </Typography>
            {compareRates.map((rate, i) => (
              <Fragment key={rate.providerName}>
                <div>
                  {rate.providerName === "nala" ? (
                    <NalaLogo />
                  ) : (
                    <h4 className={s.providerHeading}>{rate.providerName}</h4>
                  )}
                  <p className={s.rateTime}>
                    Rates as of: {formatDateToLocalTimeWithZone(rate.createdAt)}
                  </p>
                </div>
                <RateContainer hasAccentColor={i === 0}>
                  <h4 className={s.providerHeading}>
                    {fxRateHumanFriendly(rate)}
                  </h4>
                </RateContainer>
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.loadingContainer}>
          <p className={s.transparencyText}>Loading rates...</p>
        </div>
      )}
      <div style={{ marginBottom: "2em" }}>
        <CTA href={downloadNalaLink}>Download NALA ❤️</CTA>
      </div>
      <TypographyFooter>
        We believe in increasing transparency in finance. We charge as little as
        possible. No hidden fees. No surprises.
        <br />
        <a href="https://help.nala.money/en/articles/6331677-how-does-nala-compare-rates">
          How do we collect this data?
        </a>
      </TypographyFooter>
    </div>
  );
};
