import { FunctionalComponent, h, Fragment } from "preact";
import { ArrowsIcon } from "./arrowsIcon";
import s from "./fxRateCopy.module.css";

export const FxRateCopy: FunctionalComponent<{ isFetching: boolean }> = ({
  children,
  isFetching,
}) => {
  return (
    <div className={s.outer}>
      <div className={s.inner}>
        {isFetching ? (
          <span>Fetching rates...</span>
        ) : (
          <>
            {children && <ArrowsIcon className={s.arrows} />}
            {children}
          </>
        )}
      </div>
    </div>
  );
};
