import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import enIN from "date-fns/locale/en-IN";
import {
  countryCodeToCurrencyLongName,
  currenciesConfig,
} from "../../i18n/currenciesConfig";

const getLocalTimeZoneAbbreviation = (date: Date): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's local timezone
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, "zzz", { locale: enIN });
};

export const formatDateToLocalTimeWithZone = (inputDate: Date): string => {
  const timeZoneAbbreviation = getLocalTimeZoneAbbreviation(inputDate);
  return `${format(inputDate, "HH:mm")} ${timeZoneAbbreviation}`;
};

export const getInitialState = (
  fromCurrencyCode: string | null,
  toCurrencyCode: string | null
): CalculatorState => {
  return {
    fromCurrency: fromCurrencyCode
      ? {
          currencyCode: fromCurrencyCode,
          currencyName: countryCodeToCurrencyLongName(fromCurrencyCode),
          countryCode: currenciesConfig[fromCurrencyCode].countryCodes[0],
        }
      : null,
    fromAmount: "0",
    toCurrency: toCurrencyCode
      ? {
          currencyCode: toCurrencyCode,
          currencyName: countryCodeToCurrencyLongName(toCurrencyCode),
          countryCode: currenciesConfig[toCurrencyCode].countryCodes[0],
        }
      : null,
    toAmount: "0",
  };
};
