export const countryToCurrency: { [key: string]: string } = {
  // Supported send countries excluding US
  "United Kingdom": "GBP",
  Austria: "EUR",
  Belgium: "EUR",
  Cyprus: "EUR",
  Estonia: "EUR",
  Finland: "EUR",
  France: "EUR",
  Germany: "EUR",
  Greece: "EUR",
  Ireland: "EUR",
  Italy: "EUR",
  Latvia: "EUR",
  Lithuania: "EUR",
  Luxembourg: "EUR",
  Malta: "EUR",
  Netherlands: "EUR",
  Portugal: "EUR",
  Slovakia: "EUR",
  Slovenia: "EUR",
  Spain: "EUR",
};

export function getCountryFromTimezone(timeZone: string): string | null {
  const timeZoneParts = timeZone.split("/");
  if (timeZoneParts.length > 1) {
    const city = timeZoneParts[timeZoneParts.length - 1].replace(/_/g, " ");
    const cityToCountry: { [key: string]: string } = {
      // United Kingdom
      London: "United Kingdom",

      // Austria
      Vienna: "Austria",

      // Belgium
      Brussels: "Belgium",

      // Cyprus
      Nicosia: "Cyprus",
      Famagusta: "Cyprus",

      // Estonia
      Tallinn: "Estonia",

      // Finland
      Helsinki: "Finland",
      Mariehamn: "Finland", // Åland Islands

      // France
      Paris: "France",

      // Germany
      Berlin: "Germany",
      Busingen: "Germany",

      // Greece
      Athens: "Greece",

      // Ireland
      Dublin: "Ireland",

      // Italy
      Rome: "Italy",

      // Latvia
      Riga: "Latvia",

      // Lithuania
      Vilnius: "Lithuania",

      // Luxembourg
      Luxembourg: "Luxembourg",

      // Malta
      Malta: "Malta",

      // Netherlands
      Amsterdam: "Netherlands",

      // Portugal
      Lisbon: "Portugal",
      Madeira: "Portugal",
      Azores: "Portugal",

      // Slovakia
      Bratislava: "Slovakia",

      // Slovenia
      Ljubljana: "Slovenia",

      // Spain
      Madrid: "Spain",
      Ceuta: "Spain",
      Canary: "Spain",
    };
    return cityToCountry[city] || null;
  }
  return null;
}
