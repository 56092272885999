import { createRef, h, VNode, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";
import { useClickOutside } from "../../../hooks";
import { Flag } from "../../flag/flag";
import { ArrowIcon } from "../arrowIcon";
import s from "./dropdownButton.module.css";

export const DropdownButton = ({
  currencies,
  onChange,
  initialValue,
  partOfCurrencyInput,
  rightAligned,
}: {
  currencies?: CalculatorCurrency[];
  onChange: (currency: CalculatorCurrency) => void;
  initialValue: CalculatorCurrency | null;
  partOfCurrencyInput?: boolean;
  rightAligned?: boolean;
}): VNode => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState<CalculatorCurrency | null>(
    initialValue
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const ref = createRef();
  useClickOutside(ref, () => {
    setIsVisible(false);
  });

  const handleSelect = (selectedCurrency: CalculatorCurrency) => {
    setSelectedValue(selectedCurrency);
    setIsVisible(false);
    onChange(selectedCurrency);
  };

  const sendingCompareRate = !partOfCurrencyInput && !rightAligned;

  return (
    <div className={s.container} ref={ref}>
      <button
        className={`${s.button} ${
          partOfCurrencyInput === true ? s.currencyInputButton : ""
        } ${!selectedValue ? s.loading : ""}`}
        onClick={() => setIsVisible(!isVisible)}
        disabled={!selectedValue}
        style={{ cursor: !selectedValue ? "not-allowed" : "pointer" }}
      >
        {!selectedValue ? (
          <Fragment>
            <Flag code="XX" />
            <span className={s.value}>XXX</span>
          </Fragment>
        ) : (
          <Fragment>
            <Flag code={selectedValue.countryCode} />
            <span className={s.value}>
              {sendingCompareRate && 1000} {selectedValue.currencyCode}
            </span>
          </Fragment>
        )}
        <ArrowIcon />
      </button>
      {isVisible && selectedValue && currencies && (
        <ul
          className={`${s.list} ${
            partOfCurrencyInput === true ? s.currencyInputList : ""
          } ${rightAligned === true ? s.rightAligned : ""}`}
        >
          {currencies.map((currency) => (
            <li
              className={
                currency.countryCode === selectedValue.countryCode
                  ? `${s.listElement} ${s.listElementSelected}`
                  : s.listElement
              }
              key={currency.currencyCode}
              onClick={() => handleSelect(currency)}
            >
              <div className={s.listFlagCurrency}>
                <Flag code={currency.countryCode} />
                <span className={s.listElementText}>
                  {sendingCompareRate && 1000} {currency.currencyCode}
                </span>
              </div>
              <span className={s.listElementText}>{currency.currencyName}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
