import { FunctionalComponent, h } from "preact";

import S from "./rateContainer.module.css";

type RateContainer = {
  hasAccentColor?: boolean;
};

export const RateContainer: FunctionalComponent<RateContainer> = ({
  children,
  hasAccentColor,
}) => (
  <div
    className={`${S.rateContainer} ${
      hasAccentColor ? S.rateContainerAccent : ""
    }`}
  >
    {children}
  </div>
);
