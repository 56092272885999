import { countryToCurrencyMap } from "../../i18n/currenciesConfig";
import {
  countryToCurrency,
  getCountryFromTimezone,
} from "../../i18n/locationUtils";

export function getCurrencyFromUrl(
  url: string,
  defaultCurrency: string
): string {
  const lastUrlPart = url.split("/").pop();

  if (!lastUrlPart) {
    return defaultCurrency;
  }

  const matchedCountry = Object.keys(countryToCurrencyMap).find(
    (country) => country.toLowerCase() === lastUrlPart.toLowerCase()
  );

  return matchedCountry
    ? countryToCurrencyMap[matchedCountry]
    : defaultCurrency;
}

export function getDefaultFromCurrency(): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const country = getCountryFromTimezone(timeZone);

  if (country && countryToCurrency[country]) {
    return countryToCurrency[country];
  }

  return "USD";
}
