export const currenciesConfig: {
  [key: string]: {
    code: string;
    fraction: number;
    destinationAmountFraction: number;
    symbol: string;
    display: string;
    countryCodes: string[];
  };
} = {
  USD: {
    code: "USD",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "$",
    display: "USD",
    countryCodes: ["US"],
  },
  EUR: {
    code: "EUR",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "€",
    display: "EUR",
    countryCodes: ["EU"],
  },
  UGX: {
    code: "UGX",
    fraction: 0,
    destinationAmountFraction: 0,
    symbol: "UGX",
    display: "",
    countryCodes: ["UG"],
  },
  NGN: {
    code: "NGN",
    fraction: 2,
    destinationAmountFraction: 0,
    symbol: "₦",
    display: "",
    countryCodes: ["NG"],
  },
  GHS: {
    code: "GHS",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "GH₵",
    display: "",
    countryCodes: ["GH"],
  },
  GBP: {
    code: "GBP",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "£",
    display: "GBP",
    countryCodes: ["GB"],
  },
  PHP: {
    code: "PHP",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "₱",
    display: "PHP",
    countryCodes: ["PH"],
  },
  PKR: {
    code: "PKR",
    fraction: 2,
    destinationAmountFraction: 2,
    symbol: "₨",
    display: "PKR",
    countryCodes: ["PK"],
  },
  KES: {
    code: "KES",
    fraction: 2,
    destinationAmountFraction: 0,
    symbol: "KSh",
    display: "",
    countryCodes: ["KE"],
  },
  RWF: {
    code: "RWF",
    fraction: 0,
    destinationAmountFraction: 0,
    symbol: "R₣",
    display: "",
    countryCodes: ["RW"],
  },
  TZS: {
    code: "TZS",
    fraction: 2,
    destinationAmountFraction: 0,
    symbol: "TSh",
    display: "",
    countryCodes: ["TZ"],
  },
  XOF: {
    code: "XOF",
    fraction: 2,
    symbol: "CFA",
    display: "",
    destinationAmountFraction: 0,
    countryCodes: ["SN", "CI"],
  },
  XAF: {
    code: "XAF",
    fraction: 2,
    destinationAmountFraction: 0,
    symbol: "CFA",
    display: "",
    countryCodes: ["CM"],
  },
};

export const countryCodeToCurrencyLongName = (countryCode: string): string => {
  switch (countryCode) {
    case "US":
      return "United States Dollar";
    case "EU":
      return "Euro";
    case "UG":
      return "Ugandan Shilling";
    case "NG":
      return "Nigerian Naira";
    case "GH":
      return "Ghanaian Cedi";
    case "GB":
      return "British Pound";
    case "KE":
      return "Kenyan Shilling";
    case "PH":
      return "Philippine Peso";
    case "PK":
      return "Pakistani Rupee";
    case "RW":
      return "Rwandan Franc";
    case "TZ":
      return "Tanzanian Shilling";
    case "SN":
      return "Senegal CFA";
    case "CI":
      return "Côte d’Ivoire CFA";
    case "CM":
      return "Cameroon CFA";
    default:
      return countryCode;
  }
};

// Used for the URL based currency lookup
export const countryToCurrencyMap = {
  nigeria: "NGN",
  ghana: "GHS",
  cameroon: "XAF",
  kenya: "KES",
  tanzania: "TZS",
  uganda: "UGX",
  rwanda: "RWF",
  "cote-d'ivoire": "XOF",
  senegal: "XOF",
};
