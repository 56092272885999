import { FunctionComponent, h, VNode } from "preact";

export const ArrowsIcon: FunctionComponent<{
  className: string;
}> = ({ className }): VNode => (
  <span className={className}>
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4683 15.0254V4.45069M13.4683 15.0254L10.384 11.9411M13.4683 15.0254L16.5526 11.9411M4.65607 12.3817V0.925781M4.65607 0.925781L1.57178 4.01008M4.65607 0.925781L7.74037 4.01008"
        stroke="var(--theme-text-color)"
        stroke-width="1.76245"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);
