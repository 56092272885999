import { FunctionalComponent, h } from "preact";
import s from "./typography.module.css";

interface TypographyProps {
  className?: string;
}

export const Typography: FunctionalComponent<TypographyProps> = ({
  children,
  className,
}) => {
  const classes = `${s.typography}${className ? ` ${className}` : ""}`;
  return <p className={classes}>{children}</p>;
};

export const TypographyFooter: FunctionalComponent = ({ children }) => {
  return <p className={s.typographyFooter}>{children}</p>;
};
