import { h, VNode } from "preact";
import s from "./rateCalculatorTab.module.css";
import { Label } from "../../label";
import { currenciesConfig } from "../../../i18n/currenciesConfig";
import { CTA } from "../../cta";
import { DropdownButton } from "../../dropdown/button";
import { FxRateCopy } from "../../fxRateCopy";
import { Input } from "../../input/input";
import { TypographyFooter } from "../../typography";
import { useEffect, useMemo, useReducer } from "preact/hooks";
import { reducer } from "../../../reducer/reducer";
import uniqby from "lodash.uniqby";
import Big from "big.js";
import { RateCalculatorTabProps } from "../compareRatesTab/types";

export const RateCalculatorTab = ({
  fromCurrency,
  toCurrency,
  onFromCurrencyChange,
  onToCurrencyChange,
  rates: allRates,
  downloadNalaLink,
  isFetchingRates,
}: RateCalculatorTabProps): VNode => {
  const [state, dispatch] = useReducer(reducer, {
    fromCurrency,
    toCurrency,
    fromAmount: "1000",
    toAmount: "0",
  });

  const { fromAmount, toAmount } = state;

  useEffect(() => {
    if (fromCurrency) {
      dispatch({ type: "fromCurrencyChange", payload: fromCurrency });
    }
  }, [fromCurrency]);

  useEffect(() => {
    if (toCurrency) {
      dispatch({ type: "toCurrencyChange", payload: toCurrency });
    }
  }, [toCurrency]);

  const nalaRates = useMemo(() => {
    return allRates.filter((rate) => rate.providerName === "nala");
  }, [allRates]);

  useEffect(() => {
    if (nalaRates.length > 0) {
      dispatch({ type: "addPairs", payload: nalaRates });
      dispatch({ type: "fromAmountChange", payload: "1000" });
    }
  }, [nalaRates]);

  const sourceCurrencies = useMemo(
    () =>
      allRates &&
      uniqby(
        allRates
          .map((rate) => rate.sourceCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [allRates]
  );

  const destinationCurrencies = useMemo(
    () =>
      allRates &&
      uniqby(
        allRates
          .map((rate) => rate.destinationCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [allRates]
  );

  const rate = useMemo(() => {
    if (allRates && fromCurrency && toCurrency) {
      return allRates.find(
        (rate) =>
          rate.sourceCurrency.currencyCode === fromCurrency.currencyCode &&
          rate.destinationCurrency.currencyCode === toCurrency.currencyCode &&
          rate.providerName === "nala"
      );
    }
  }, [fromCurrency, allRates, toCurrency]);

  const fxRateHumanFriendlyText = useMemo(
    () =>
      rate && fromCurrency && toCurrency
        ? `1 ${fromCurrency.currencyCode} ≈ ${Big(rate.rate).round(
            2,
            Big.roundUp
          )} ${toCurrency.currencyCode}`
        : "Rate unavailable",
    [fromCurrency, toCurrency, rate]
  );

  return (
    <div className={s.tabContainer}>
      <Label>You send</Label>
      <div>
        <div className={s.dropdownInputContainer}>
          <DropdownButton
            initialValue={fromCurrency}
            currencies={sourceCurrencies}
            onChange={onFromCurrencyChange}
            partOfCurrencyInput
          />
          <Input
            value={Number(fromAmount) > 0 ? fromAmount : ""}
            allowedPrecision={
              fromCurrency
                ? currenciesConfig[fromCurrency.currencyCode].fraction
                : 2
            }
            onChange={(payload) =>
              dispatch({
                type: "fromAmountChange",
                payload,
              })
            }
          />
        </div>
      </div>

      <div className={s.fxRateSummaryContainer}>
        <FxRateCopy isFetching={isFetchingRates}>
          {fxRateHumanFriendlyText}
        </FxRateCopy>
      </div>

      <Label>Individual receives</Label>
      <div style={{ marginBottom: "2em" }}>
        <div className={s.dropdownInputContainer}>
          <DropdownButton
            initialValue={toCurrency}
            currencies={destinationCurrencies}
            onChange={onToCurrencyChange}
            partOfCurrencyInput
          />
          <Input
            value={
              rate ? (Number(toAmount) > 0 ? toAmount : "") : "Rate unavailable"
            }
            allowedPrecision={
              toCurrency
                ? currenciesConfig[toCurrency.currencyCode]
                    .destinationAmountFraction
                : 2
            }
            onChange={(payload) =>
              dispatch({
                type: "toAmountChange",
                payload,
              })
            }
            disabled={!rate}
          />
        </div>
      </div>

      <div style={{ marginBottom: "2em" }}>
        <CTA href={downloadNalaLink}>Download NALA ❤️</CTA>
      </div>

      <TypographyFooter>
        Looking for our business rates?{" "}
        <a href="mailto:sales@nala.com">Contact sales!</a> We charge fees for
        certain transactions to cover our cost.
      </TypographyFooter>
    </div>
  );
};
