import habitat from "preact-habitat";

import Calculator from "./components/calculator";
// basic styles + theme
import "./style.css";

const _habitat = habitat(Calculator);

_habitat.render({
  selector: '[data-widget-host="habitat"]',
  clean: true,
});
