import { currenciesConfig } from "../i18n/currenciesConfig";
import fx from "./fx";

export const calculate = (state: CalculatorState): CalculatorState => {
  const { fromCurrency, toCurrency, fromAmount, rates } = state;

  if (!fromCurrency || !toCurrency || !rates) {
    return state; // Return the current state if essential data is missing
  }

  const rate = rates.find(
    (rate) =>
      rate.sourceCurrency.countryCode === fromCurrency.countryCode &&
      rate.destinationCurrency.countryCode === toCurrency.countryCode
  );

  if (!rate) {
    return state; // Return the current state if no matching rate is found
  }

  const currencyConfig = currenciesConfig[toCurrency.currencyCode];
  return {
    ...state,
    toAmount: fx.convertToCurrency({
      fromAmount: Number(fromAmount),
      exchangeRate: rate.rate,
      toCurrency: currencyConfig,
    }),
  };
};

export const reducer = (
  state: CalculatorState,
  action: Action
): CalculatorState => {
  switch (action.type) {
    case "fromCurrencyChange":
      return calculate({
        ...state,
        fromCurrency: action.payload,
        fromAmount: "1000", // Reset to a default amount when currency changes
      });
    case "toCurrencyChange":
      return calculate({
        ...state,
        toCurrency: action.payload,
      });
    case "fromAmountChange":
      return calculate({
        ...state,
        fromAmount: action.payload,
      });
    case "toAmountChange":
      return calculate({
        ...state,
        toAmount: action.payload,
      });
    case "addPairs":
      return calculate({
        ...state,
        rates: action.payload,
      });
    default:
      return state;
  }
};
